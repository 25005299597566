// src/modals/DataFilesContent.js

import React, { useState } from "react";
import { Download } from "react-feather";
import PropTypes from "prop-types";

const DataFilesContent = ({ files }) => {
  // State to track which files failed to load
  const [fileErrorIndices, setFileErrorIndices] = useState({});

  // Function to extract file name from an object
  const extractFileName = (file) => {
    if (!file || !file.fileName) return `file-${Date.now()}`;
    return file.fileName;
  };

  // Function to handle file download
  const handleDownload = async (file) => {
    const { signedUrl, fileName } = file;
    console.log("Signed URL:", signedUrl);

    if (!signedUrl) {
      console.error("Signed URL is undefined.");
      alert("File URL is invalid.");
      return;
    }

    try {
      const response = await fetch(signedUrl, { method: "GET" });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
      alert("Failed to download the file. Please try again.");
    }
  };

  // Handler for file load error
  const handleFileError = (index) => {
    setFileErrorIndices((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  return (
    <div className="flex flex-col space-y-6 h-full">
      {files && files.length > 0 ? (
        <div className="flex-grow overflow-y-auto">
          <div className="space-y-6">
            {files.map((file, index) => (
              <div key={index} className="relative">
                {/* Download Button */}
                <button
                  onClick={() => handleDownload(file)}
                  className="absolute bottom-2 right-2 text-white bg-gray-600 hover:bg-gray-900 p-2 rounded-full shadow-md focus:outline-none"
                  aria-label={`Download ${extractFileName(file)}`}
                  title="Download File"
                >
                  <Download size={20} />
                </button>

                {/* Displayed File */}
                <div className="w-full h-100 flex items-center justify-center border p-4 rounded-md bg-gray-100">
                  {fileErrorIndices[index] ? (
                    <p className="text-gray-500">Download File</p>
                  ) : (
                    <img
                      src={file.signedUrl}
                      alt={extractFileName(file)}
                      className="object-contain w-full h-full rounded-md"
                      onError={() => handleFileError(index)}
                      loading="lazy"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500">No files available.</p>
      )}
    </div>
  );
};

DataFilesContent.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      signedUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DataFilesContent;
