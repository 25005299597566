import React, { useState, useEffect, useCallback } from "react";
import SheetTable from "./SheetTable.js";
import SheetsTabs from "./SheetsTabs.js";
import SheetHeader from "./SheetHeader.js";
import { toast } from "react-toastify";
import {
  sheetsGetAllService,
  sheetsCreateSheetService,
  sheetAssistantService,
} from "../../services/apiServices.js";
import IconButton from "../common/IconButton.js";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import AiInput from "../common/AiInput.js";
import AiPanel from "../common/AiPanel.js";

const SheetsWrapper = () => {
  // Initialize activeSheetId from localStorage if available.
  const [activeSheetId, setActiveSheetId] = useState(() => {
    return localStorage.getItem("activeSheetId") || null;
  });
  
  const [sheets, setSheets] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Store conversations per sheet (keyed by sheet ID)
  const [sheetConversations, setSheetConversations] = useState({});

  // Persist activeSheetId changes to localStorage.
  useEffect(() => {
    if (activeSheetId) {
      localStorage.setItem("activeSheetId", activeSheetId);
    }
  }, [activeSheetId]);

  // Handle sending the message (and file) to the assistant.
  const handleSend = async ({ sheetId, message, files }) => {
    console.log("Sending message:", message);
    console.log("Sheet ID:", sheetId);

    setIsPanelOpen(true);
    setIsLoading(true);

    // Append the new user message to the conversation for this sheet
    setSheetConversations((prev) => {
      const conversation = prev[sheetId] || [];
      return {
        ...prev,
        [sheetId]: [...conversation, { user: message, ai: "" }],
      };
    });

    // Build FormData to send
    const formData = new FormData();
    formData.append("sheetId", sheetId);
    formData.append("message", message);
    files.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });

    try {
      const data = await sheetAssistantService(formData);
      // Update the last message in the conversation with the AI's response
      setSheetConversations((prev) => {
        const conversation = prev[sheetId] || [];
        if (conversation.length > 0) {
          conversation[conversation.length - 1] = {
            ...conversation[conversation.length - 1],
            ai: data.response,
          };
        }
        return { ...prev, [sheetId]: conversation };
      });
    } catch (error) {
      console.error("Error fetching assistant response:", error);
      setSheetConversations((prev) => {
        const conversation = prev[sheetId] || [];
        if (conversation.length > 0) {
          conversation[conversation.length - 1] = {
            ...conversation[conversation.length - 1],
            ai: "This feature is coming soon...",
          };
        }
        return { ...prev, [sheetId]: conversation };
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closePanel = () => {
    setIsPanelOpen(false);
  };

  // Fetch sheets from the backend.
  const getSheets = useCallback(async () => {
    try {
      const sheetData = await sheetsGetAllService();
      setSheets(sheetData);
      // If the stored activeSheetId doesn't exist in the fetched data,
      // set the first sheet as the active sheet.
      if (
        sheetData.length > 0 &&
        !sheetData.some((sheet) => sheet._id === activeSheetId)
      ) {
        setActiveSheetId(sheetData[0]._id);
      }
    } catch (error) {
      console.error("Error fetching sheets:", error);
    }
  }, [activeSheetId]);

  useEffect(() => {
    getSheets();
  }, [getSheets]);

  const handleCreateSheet = async () => {
    try {
      const existingTitles = sheets.map((sheet) => sheet.title);
      let defaultTitle = "Untitled Sheet";
      let count = 1;
      while (existingTitles.includes(defaultTitle)) {
        defaultTitle = `Untitled Sheet ${count}`;
        count += 1;
      }
      const data = await sheetsCreateSheetService(defaultTitle);
      setSheets((prevSheets) => [...prevSheets, data.sheet]);
      setActiveSheetId(data.sheet._id);
      toast.success("✅ New sheet created! Click the title to rename it.");
    } catch (error) {
      console.error("Error creating sheet:", error);
    }
  };

  const handleSheetAction = (action, payload) => {
    try {
      if (action === "rename") {
        const { newTitle } = payload;
        console.log(`Renaming sheet ${activeSheetId} to "${newTitle}"`);
        setSheets((prevSheets) =>
          prevSheets.map((sheet) =>
            sheet._id === activeSheetId ? { ...sheet, title: newTitle } : sheet
          )
        );
      }
    } catch (error) {
      console.error("Error updating sheet:", error);
    }
  };

  const activeSheet = sheets.find((sheet) => sheet._id === activeSheetId);

  return (
    <div>
      {sheets.length > 0 ? (
        <div>
          <SheetsTabs
            sheets={sheets}
            activeSheetId={activeSheetId}
            setActiveSheetId={setActiveSheetId}
            onCreateSheet={handleCreateSheet}
          />
          <SheetHeader sheet={activeSheet} onSheetUpdate={handleSheetAction} />
          {activeSheet ? (
            <SheetTable data={[activeSheet]} />
          ) : (
            <p>No active sheet selected.</p>
          )}
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-xl mb-4 p-6">
            Your sheets will load here. If your sheets are not showing, try
            refreshing the page.
            <br />
            <br />
            If you don't have any sheets, click below to create one.
          </h2>
          <IconButton
            onClick={handleCreateSheet}
            icon={<PlusIcon className="w-5 h-5" />}
            text="Create New Sheet"
          />
        </div>
      )}
      <AiPanel
        isOpen={isPanelOpen}
        onClose={closePanel}
        isLoading={isLoading}
        messages={sheetConversations[activeSheetId] || []}
        sheetTitle={activeSheet ? activeSheet.title : "Sheet"}
      />
      {activeSheetId && (
        <AiInput
          onSend={handleSend}
          sheetId={activeSheetId}
          sheetTitle={activeSheet ? activeSheet.title : "data"}
        />
      )}
    </div>
  );
};

export default SheetsWrapper;
