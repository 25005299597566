// src/components/sheets/SheetTable.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  sheetUpdateDataService,
  sheetAddColumnService,
  sheetUpdateColumnService,
  sheetDeleteColumnService,
  sheetReorderColumnsService,
} from "../../services/apiServices.js";
import { useModal } from "../../contexts/ModalContext.js";
import DataFilesContent from "../modals/DataFilesContent.js";
import DeleteRowContent from "../modals/DeleteRowContent.js";
import DeleteColumnContent from "../modals/DeleteColumnContent.js";
import UpdateColumnContent from "../modals/UpdateColumnContent.js";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { ReactComponent as MinusIcon } from "../../assets/minus.svg";
import { ReactComponent as BinIcon } from "../../assets/bin.svg";
import { ReactComponent as DragIcon } from "../../assets/drag.svg";
import { ReactComponent as FilesIcon } from "../../assets/files.svg";

// Import @dnd-kit components for drag-and-drop functionality
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Define a base class for header cells without border classes
const baseHeaderCellClass = "text-left py-1 pl-2 pr-1 whitespace-nowrap";

/**
 * SortableHeader Component
 * Renders a table header cell that can be sorted via drag-and-drop.
 * Conditionally hides the drag handle on mobile devices.
 */
const SortableHeader = ({
  id,
  children,
  onRemove,
  isFirst,
  isLast,
  isMobile,
}) => {
  // Initialize sortable functionality using useSortable hook
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  // Apply transformation styles during dragging
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  // Determine border classes based on position
  const borderClasses = `${!isFirst ? "border-l border-gray-300" : ""} ${
    !isLast ? "border-r border-gray-300" : ""
  }`;

  return (
    <th
      ref={setNodeRef}
      style={style}
      className={`${baseHeaderCellClass} relative ${borderClasses}`}
    >
      <div className="flex items-center justify-between">
        {children}
        {/* Render Icons for Editable Columns */}
        {onRemove && (
          <div className="flex items-center space-x-1 ml-2">
            {/* Minus Icon for Removing Column */}
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); // Prevent triggering the header edit
                onRemove();
              }}
              className="focus:outline-none bg-gray-50 hover:bg-gray-100 p-1 rounded"
              aria-label={`Delete column`}
            >
              <MinusIcon className="w-5 h-5" />
            </button>

            {/* Render Drag Icon as Drag Handle, except on Mobile */}
            {!isMobile && (
              <button
                {...listeners}
                {...attributes}
                className="focus:outline-none bg-transparent hover:bg-gray-100 p-1 rounded"
                aria-label="Drag handle"
              >
                <DragIcon className="w-5 h-5 cursor-grab" />
              </button>
            )}
          </div>
        )}
      </div>
    </th>
  );
};

SortableHeader.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onRemove: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isMobile: PropTypes.bool,
};

/**
 * SheetTable Component
 * Renders a dynamic table with sortable columns and various interactive features.
 * Hides drag functionality and drag buttons on mobile devices.
 */
const SheetTable = ({ data }) => {
  const { openModal, closeModal } = useModal();
  const [localData, setLocalData] = useState(data);

  // State to track if the screen is mobile
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Update local data when props change
    setLocalData(data);
  }, [data]);

  useEffect(() => {
    // Function to check if the screen is mobile based on width
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Define mobile breakpoint (e.g., 768px)
    };

    // Initial check
    checkIsMobile();

    // Add event listener for window resize to update isMobile
    window.addEventListener("resize", checkIsMobile);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  const [editingCell, setEditingCell] = useState({ row: null, column: null });
  const [cellValue, setCellValue] = useState("");

  // Initialize sensors
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  // Always pass both sensors to maintain consistent dependency array size
  const sensors = useSensors(pointerSensor, keyboardSensor);

  // If there's no data, display a message
  if (!localData || localData.length === 0) {
    return <p>No data entries available.</p>;
  }

  const sheet = localData[0];
  const sheetId = sheet._id;

  // Define protected columns that cannot be removed or reordered
  const protectedColumns = [
    "Files",
    "Logged By",
    "Logged With",
    "Date Logged",
    "Time Logged",
  ];

  // Always include "#" as the first fixed column
  const fixedColumns = [
    "#",
    ...sheet.columns.filter((col) => protectedColumns.includes(col)),
  ];

  // Exclude all fixed columns from sortableColumns
  const sortableColumns = sheet.columns.filter(
    (col) => !protectedColumns.includes(col)
  );

  // Define fixed column widths for consistent sizing
  const fixedColumnWidths = {
    "#": 60,
    Files: 60,
    "Logged By": 160,
    "Logged With": 260,
    "Date Logged": 200,
    "Time Logged": 200,
  };

  // Define class names for header and cells
  const headerCellClass =
    "text-left py-1 px-2 border border-gray-300 border-t-0 whitespace-nowrap";
  const cellClass =
    "text-left py-1 px-2 border border-gray-300 whitespace-nowrap";

  // Calculate maximum number of thumbnails for Files column
  const maxThumbnails = Math.max(
    ...sheet.dataEntries.map((entry) =>
      Array.isArray(entry.Files) ? entry.Files.length : 0
    )
  );

  const thumbnailWidth = 30;
  const spacing = 8;

  const calculatedWidth = maxThumbnails * (thumbnailWidth + spacing);

  /**
   * Renders the Files icon which opens a modal when clicked.
   * @param {Array} filesSignedUrls - Array of signed URLs for files.
   */
  const renderFilesIcon = (filesSignedUrls) => {
    const handleFilesIconClick = () => {
      openModal("Files", <DataFilesContent files={filesSignedUrls} />);
    };

    return (
      <button
        onClick={handleFilesIconClick}
        className="focus:outline-none pr-6 cursor-pointer"
        aria-label="Open Files Modal"
      >
        <FilesIcon className="w-7 h-7 cursor-pointer" />
      </button>
    );
  };

  /**
   * Handles adding a new column to the sheet.
   */
  const handleAddColumn = async () => {
    let baseName = "New Column";
    let newColumnName = baseName;
    let count = 1;

    // Ensure the new column name is unique
    while (sheet.columns.includes(newColumnName)) {
      newColumnName = `${baseName} ${count}`;
      count += 1;
    }

    try {
      // Call API to add the new column
      const data = await sheetAddColumnService(sheetId, newColumnName);
      const createdColumn = data.column;

      // Update local state with the new column
      const updatedColumns = [...sheet.columns, createdColumn];
      const updatedDataEntries = sheet.dataEntries.map((entry) => ({
        ...entry,
        [createdColumn]: "",
      }));

      setLocalData([
        {
          ...sheet,
          columns: updatedColumns,
          dataEntries: updatedDataEntries,
        },
      ]);
      toast.info(`Column "${createdColumn}" added successfully.`);
    } catch (error) {
      console.error("Error adding column:", error);
      toast.error("Failed to add column.", { icon: false });
    }
  };

  /**
   * Handles removing a column from the sheet by opening a confirmation modal.
   * @param {string} columnName - Name of the column to remove.
   */
  const handleRemoveColumn = (columnName) => {
    openModal(
      <div className="flex items-center text-red-600">
        <BinIcon className="w-6 h-6 text-red-500 mr-2" />
        <h2 className="text-xl font-semibold">Delete Column</h2>
      </div>,
      <DeleteColumnContent
        columnName={columnName}
        onDeleteColumnSuccess={async () => {
          try {
            // Call API to delete the column
            await sheetDeleteColumnService(sheetId, columnName);

            // Update local state by removing the column
            const updatedColumns = sheet.columns.filter(
              (col) => col !== columnName
            );
            const updatedDataEntries = sheet.dataEntries.map(
              ({ [columnName]: _, ...rest }) => rest
            );

            setLocalData([
              {
                ...sheet,
                columns: updatedColumns,
                dataEntries: updatedDataEntries,
              },
            ]);
            toast.info(`Column deleted. Refreshing...`);

            window.location.reload();
          } catch (error) {
            console.error(`Error deleting column "${columnName}":`, error);
            toast.error(`Failed to delete column "${columnName}".`, {
              icon: false,
            });
          }
          closeModal();
        }}
      />
    );
  };

  /**
   * Handles deleting a row by opening a confirmation modal.
   * @param {number} rowIndex - Index of the row to delete.
   */
  const handleDeleteRow = (rowIndex) => {
    openModal(
      <div className="flex items-center text-red-600">
        <BinIcon className="w-6 h-6 text-red-500 mr-2" />
        <h2 className="text-xl font-semibold">Delete Row</h2>
      </div>,
      <DeleteRowContent
        rowIndex={rowIndex}
        onDeleteRowSuccess={async (index) => {
          try {
            // Update local entries by removing the specified row
            const updatedEntries = sheet.dataEntries.filter(
              (_, idx) => idx !== index
            );

            setLocalData([
              {
                ...sheet,
                dataEntries: updatedEntries,
              },
            ]);

            // Call API to update the data entries
            await sheetUpdateDataService(sheetId, updatedEntries);
            toast.info(`Row deleted. Refreshing...`);

            window.location.reload();
          } catch (err) {
            console.error("Error deleting row:", err);
            toast.error("Failed to delete row.", { icon: false });
          }
        }}
      />
    );
  };

  /**
   * Handles clicking on a cell to enable editing.
   * @param {number} rowIndex - Index of the row.
   * @param {number} columnIndex - Index of the column.
   * @param {string} value - Current value of the cell.
   */
  const handleCellClick = (rowIndex, columnIndex, value) => {
    const columnName = sheet.columns[columnIndex - 1];
    // Prevent editing for protected columns or the Files column
    if (
      protectedColumns.includes(columnName) ||
      columnName === "#" ||
      columnName === "Files"
    )
      return;

    setEditingCell({ row: rowIndex, column: columnIndex });
    setCellValue(value);
  };

  /**
   * Handles changes to the cell input value.
   * @param {object} e - Event object.
   */
  const handleCellValueChange = (e) => {
    setCellValue(e.target.value);
  };

  /**
   * Saves the edited cell value by updating the local state and calling the API.
   * @param {number} rowIndex - Index of the row.
   * @param {number} columnIndex - Index of the column.
   */
  const handleCellValueSave = async (rowIndex, columnIndex) => {
    const columnName = sheet.columns[columnIndex - 1];
    const newValue = cellValue;

    // Update local entries with the new cell value
    const updatedEntries = sheet.dataEntries.map((entry, idx) =>
      idx === rowIndex ? { ...entry, [columnName]: newValue } : entry
    );

    setLocalData([
      {
        ...sheet,
        dataEntries: updatedEntries,
      },
    ]);

    // Exit editing mode
    setEditingCell({ row: null, column: null });

    try {
      // Call API to update the data entries
      await sheetUpdateDataService(sheetId, updatedEntries);
    } catch (error) {
      // Revert to previous state on error
      const revertedEntries = sheet.dataEntries.map((entry, idx) =>
        idx === rowIndex
          ? { ...entry, [columnName]: sheet.dataEntries[rowIndex][columnName] }
          : entry
      );

      setLocalData([
        {
          ...sheet,
          dataEntries: revertedEntries,
        },
      ]);

      console.error("Error updating cell:", error);
      toast.error("Failed to update cell.", { icon: false });
    }
  };

  /**
   * Handles pressing the Enter key to save cell edits.
   * @param {object} e - Event object.
   * @param {number} rowIndex - Index of the row.
   * @param {number} columnIndex - Index of the column.
   */
  const handleKeyPress = (e, rowIndex, columnIndex) => {
    if (e.key === "Enter") {
      handleCellValueSave(rowIndex, columnIndex);
    }
  };

  /**
   * Handles losing focus on a cell input to save edits.
   * @param {number} rowIndex - Index of the row.
   * @param {number} columnIndex - Index of the column.
   */
  const handleBlur = (rowIndex, columnIndex) => {
    handleCellValueSave(rowIndex, columnIndex);
  };

  /**
   * Handles renaming a column by opening a rename modal.
   * @param {string} columnName - Current name of the column.
   */
  const handleRenameColumn = (columnName) => {
    openModal(
      <div className="flex items-center">
        <h2 className="text-xl font-semibold">Rename Column</h2>
      </div>,
      <UpdateColumnContent
        currentName={columnName}
        onRenameSuccess={async (newName) => {
          try {
            // Call API to update the column name
            await sheetUpdateColumnService(sheetId, columnName, newName);

            // Update local state with the new column name
            const updatedColumns = sheet.columns.map((col) =>
              col === columnName ? newName : col
            );
            const updatedDataEntries = sheet.dataEntries.map((entry) => {
              const { [columnName]: value, ...rest } = entry;
              return { ...rest, [newName]: value };
            });

            setLocalData([
              {
                ...sheet,
                columns: updatedColumns,
                dataEntries: updatedDataEntries,
              },
            ]);
            toast.info(`Column renamed. Refreshing...`);

            window.location.reload();
          } catch (error) {
            console.error("Error renaming column:", error);
            toast.error("Failed to rename column.", { icon: false });
            throw error; // To let the modal know about the error
          }
        }}
      />
    );
  };

  /**
   * Handles the end of a drag event to reorder columns.
   * @param {object} event - Drag event object containing active and over items.
   */
  const handleDragEnd = async (event) => {
    const { active, over } = event;

    // If the item was dropped over a different item, proceed with reorder
    if (active.id !== over?.id) {
      const oldIndex = sortableColumns.indexOf(active.id);
      const newIndex = sortableColumns.indexOf(over.id);

      // Ensure both indices are valid
      if (oldIndex === -1 || newIndex === -1) return;

      // Reorder the sortable columns array
      const newSortableColumns = arrayMove(sortableColumns, oldIndex, newIndex);

      // **Construct newColumns without including the fixed "#" column**
      const newColumns = [...protectedColumns, ...newSortableColumns];

      // Reorder data entries based on new column order
      const newDataEntries = sheet.dataEntries.map((entry) => {
        const reorderedEntry = {};
        newColumns.forEach((col) => {
          reorderedEntry[col] = entry[col] || "";
        });
        return reorderedEntry;
      });

      // Optimistically update the local state
      setLocalData([
        {
          ...sheet,
          columns: newColumns,
          dataEntries: newDataEntries,
        },
      ]);

      try {
        // Call API to persist the new column order
        await sheetReorderColumnsService(sheetId, newColumns);
        toast.info("Refresh to see reordered columns.");
      } catch (error) {
        console.error("Error reordering columns:", error);
        toast.error("Failed to reorder columns.", { icon: false });
        // Optionally, revert the change on error
        setLocalData([sheet]);
      }
    }
  };

  return (
    <div key={sheet._id} className="px-4 md:px-12 pb-[450px]">
      <div className="">
        <div className="border-gray-300 border rounded-md overflow-x-auto custom-scrollbar">
          {/* DndContext provides the drag-and-drop context */}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            {/* SortableContext defines the sortable items */}
            <SortableContext
              items={sortableColumns}
              strategy={horizontalListSortingStrategy}
            >
              <table className="w-full border-collapse table-auto">
                <thead>
                  <tr>
                    {/* Fixed Columns */}
                    {fixedColumns.map((column, index) => (
                      <th
                        key={column}
                        className={`${headerCellClass} ${
                          index === 0 ? "border-l-0" : ""
                        } ${
                          index === fixedColumns.length - 1 ? "border-r-1" : ""
                        } cursor-not-allowed`}
                        style={{
                          width: `${fixedColumnWidths[column] || 150}px`,
                        }}
                      >
                        {column === "#"
                          ? "#"
                          : column === "Files"
                          ? "Files"
                          : column}
                      </th>
                    ))}

                    {/* Sortable Columns */}
                    {sortableColumns.map((column, index) => (
                      <SortableHeader
                        key={column}
                        id={column}
                        onRemove={
                          !protectedColumns.includes(column)
                            ? () => handleRemoveColumn(column)
                            : null
                        }
                        isFirst={index === 0}
                        isLast={sortableColumns.length === index + 1}
                        isMobile={isMobile} // Pass isMobile prop to hide drag handle on mobile
                      >
                        <span
                          onClick={() => handleRenameColumn(column)}
                          className="block cursor-pointer hover:underline"
                          title="Click to rename column"
                        >
                          {column}
                        </span>
                      </SortableHeader>
                    ))}

                    {/* Plus Icon Header for Adding Columns */}
                    <th
                      className={`${headerCellClass} border-r-0 border-t-0 cursor-not-allowed`}
                      style={{ width: "80px" }}
                    >
                      <button
                        onClick={handleAddColumn}
                        className="focus:outline-none md:ml-2 mt-1 rounded-md hover:bg-gray-200 px-1"
                        aria-label="Add Column"
                      >
                        <PlusIcon className="w-5 h-5" />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sheet.dataEntries.map((entry, entryIndex) => (
                    <tr key={`${sheet._id}-${entryIndex}`}>
                      {/* Fixed Columns */}
                      {fixedColumns.map((column, colIndex) => (
                        <td
                          key={colIndex}
                          className={`${cellClass} ${
                            colIndex === 0 ? "border-l-0" : ""
                          } ${
                            colIndex === fixedColumns.length - 1
                              ? "border-r-0"
                              : ""
                          } ${
                            entryIndex === sheet.dataEntries.length - 1
                              ? "border-b-0"
                              : ""
                          } cursor-not-allowed`}
                          style={{
                            width: `${fixedColumnWidths[column] || 150}px`,
                          }}
                        >
                          {column === "#" ? (
                            <div className="flex items-center space-x-2">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleDeleteRow(entryIndex);
                                }}
                                className="focus:outline-none bg-gray-50 rounded-md hover:bg-gray-200 px-1"
                                aria-label={`Delete row ${entryIndex + 1}`}
                              >
                                <MinusIcon className="w-4 h-5" />
                              </button>
                              <span>{entryIndex + 1}</span>
                            </div>
                          ) : column === "Files" ? (
                            // Render Files icon with modal on click
                            renderFilesIcon(entry.FilesSignedUrls)
                          ) : (
                            entry[column] || "N/A"
                          )}
                        </td>
                      ))}

                      {/* Sortable Columns */}
                      {sortableColumns.map((column, colIndex) => (
                        <td
                          key={colIndex}
                          className={`${cellClass} ${
                            colIndex === sortableColumns.length - 1
                              ? "border-r border-gray-300"
                              : ""
                          } ${
                            entryIndex === sheet.dataEntries.length - 1
                              ? "border-b-0"
                              : ""
                          } ${
                            protectedColumns.includes(column) || column === "#"
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          style={
                            column === "Files"
                              ? {
                                  minWidth: `${calculatedWidth}px`,
                                  maxWidth: "500px",
                                }
                              : { minWidth: "150px" }
                          }
                          onClick={() =>
                            handleCellClick(
                              entryIndex,
                              sheet.columns.indexOf(column) + 1,
                              entry[column] || ""
                            )
                          }
                        >
                          {editingCell.row === entryIndex &&
                          editingCell.column ===
                            sheet.columns.indexOf(column) + 1 ? (
                            // Render input field for editing
                            <input
                              type="text"
                              value={cellValue}
                              onChange={handleCellValueChange}
                              onBlur={() =>
                                handleBlur(
                                  entryIndex,
                                  sheet.columns.indexOf(column) + 1
                                )
                              }
                              onKeyPress={(e) =>
                                handleKeyPress(
                                  e,
                                  entryIndex,
                                  sheet.columns.indexOf(column) + 1
                                )
                              }
                              autoFocus
                              className="w-full border-none outline-none bg-transparent"
                            />
                          ) : column === "Files" ? (
                            // Render Files icon if not editing
                            renderFilesIcon(entry.FilesSignedUrls)
                          ) : (
                            entry[column] || "N/A"
                          )}
                        </td>
                      ))}

                      {/* Delete Row Cell (Empty) */}
                      <td
                        className={`${cellClass} border-r-0 border-t-0 ${
                          entryIndex === sheet.dataEntries.length - 1
                            ? "border-b-0"
                            : ""
                        } cursor-not-allowed`}
                        style={{ width: "40px" }}
                      ></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </div>
  );
};

SheetTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SheetTable;
