import React, { useState, useRef, useEffect } from "react";
import arrow from "../../assets/up-arrow.svg";
import attachments from "../../assets/attachments.svg";
import PropTypes from "prop-types";
import { X } from "react-feather";

// Allowed MIME types – only image formats
const allowedMimeTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
  "image/gif", 
];

const MAX_FILES = 10;
const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15MB

const AiInput = ({ onSend, sheetId, sheetTitle }) => {
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSend = () => {
    const trimmedMessage =
      message.trim() || (selectedFiles.length > 0 ? "Images attached" : "");
    if (validateInput(trimmedMessage)) {
      console.log("Sending to parent component:", {
        sheetId,
        message: trimmedMessage,
        files: selectedFiles,
      });

      onSend({ sheetId, message: trimmedMessage, files: selectedFiles });

      setMessage("");
      setSelectedFiles([]);
      setError("");
    }
  };

  const validateInput = (msg) => {
    if (!sheetId) {
      setError("Sheet ID is missing.");
      return false;
    }
    if (!msg && selectedFiles.length === 0) {
      setError("Message or at least one image is required.");
      return false;
    }
    return true;
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleFileSelection = (e) => {
    const files = Array.from(e.target.files);
    let newFiles = [...selectedFiles];

    for (let file of files) {
      if (!allowedMimeTypes.includes(file.type)) {
        alert(
          `Unsupported file type: ${file.type}. Please select a valid image file.`
        );
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        alert(`File size exceeds the limit of 10MB: ${file.name}`);
        return;
      }
      if (newFiles.length >= MAX_FILES) {
        alert(`You can only upload a maximum of ${MAX_FILES} images.`);
        return;
      }
      newFiles.push(file);
    }
    setSelectedFiles(newFiles);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="fixed bottom-[20px] left-1/2 transform -translate-x-1/2 w-11/12 max-w-6xl bg-white p-4 h-16 flex items-center rounded-xl shadow-md pr-4 border-2">
      {selectedFiles.length > 0 && (
        <div className="absolute bottom-20 left-2 flex flex-wrap gap-2 bg-gray-200 p-2 rounded">
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              className="flex items-center bg-white px-2 py-1 rounded"
            >
              <span className="text-sm truncate max-w-xs">{file.name}</span>
              <button
                onClick={() => handleRemoveFile(index)}
                className="ml-2 text-gray-600 hover:text-gray-800"
              >
                <X size={16} />
              </button>
            </div>
          ))}
        </div>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelection}
        accept={allowedMimeTypes.join(",")}
        multiple
      />
      <div className="relative mt-1">
        <button
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          className="text-gray-500 focus:outline-none"
          aria-label="Upload Images"
          onClick={handleUploadClick}
        >
          <img
            src={attachments}
            alt="upload"
            style={{ width: "24px", height: "24px" }}
          />
        </button>
        {showTooltip && (
          <div className="absolute bottom-10 left-[90px] w-[200px] transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2">
            Attach up to 10 images (Max size: 10MB each)
          </div>
        )}
      </div>
      <input
        type="text"
        ref={inputRef}
        className="w-full p-2 focus:outline-none bg-transparent"
        placeholder={"Ask Me About Your " + sheetTitle}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <button
        onClick={handleSend}
        className="text-gray-500 hover:text-gray-700 focus:outline-none pl-4"
      >
        <img src={arrow} alt="Send" style={{ width: "32px", height: "32px" }} />
      </button>
    </div>
  );
};

AiInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  sheetId: PropTypes.string.isRequired,
  sheetTitle: PropTypes.string.isRequired,
};

export default AiInput;
