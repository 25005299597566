import React, { useState } from "react";
import { ChevronsUp, ChevronsDown } from "react-feather";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const AiPanel = ({
  isOpen,
  onClose,
  isLoading = false,
  messages = [],
  sheetTitle,
}) => {
  const [isMinimized, setIsMinimized] = useState(false);

  if (!isOpen) return null;

  return (
    <div
      className={`fixed bottom-[90px] left-1/2 transform -translate-x-1/2 w-11/12 max-w-6xl bg-white p-6 border border-gray-100 rounded-xl shadow-md ${
        isMinimized ? "h-[80px]" : "h-2/5"
      } flex flex-col transition-all duration-300`}
    >
      <div className="flex items-center justify-between px-0 md:px-4 mb-4">
        <h1 className="text-lg font-semibold">{sheetTitle} Assistant</h1>
        <button
          onClick={() => setIsMinimized(!isMinimized)}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Toggle Panel Size"
        >
          {isMinimized ? <ChevronsUp size={24} /> : <ChevronsDown size={24} />}
        </button>
      </div>

      {!isMinimized && (
        <div className="mt-2 flex-grow overflow-y-auto px-0 md:px-4">
          {messages.map((msg, index) => (
            <div key={index} className="mb-4">
              {msg.user && (
                <div className="p-3 bg-gray-50 rounded-md">
                  <span className="text-gray-800">{msg.user}</span>
                </div>
              )}
              {msg.ai && (
                <div className="mt-2 p-3 bg-green-50 rounded-md text-green-800 prose">
                  <ReactMarkdown>{msg.ai}</ReactMarkdown>
                  {msg.ai.toLowerCase().includes("refresh") && (
                    <button
                      onClick={() => window.location.reload()}
                      className="mt-2 px-3 py-1 text-xs font-medium text-blue-500 bg-blue-100 border border-blue-500 rounded-md hover:bg-blue-200 transition-colors"
                    >
                      View updated sheet
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
          {isLoading && (
            <div className="space-y-4">
              <div className="h-[48px] bg-green-100 rounded w-full animate-pulse"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AiPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  messages: PropTypes.array,
  sheetTitle: PropTypes.string.isRequired,
};

export default AiPanel;
