// src/modals/ManageBillingContent.js

import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  stripeCreateCheckoutSessionService,
  stripeCreateCustomerPortalSessionService,
  stripeUpdateSubscriptionFeatureService,
  stripeGetSubscriptionDetailsService,
  userGetAccountService, // Import the user account service
} from "../../services/apiServices.js";

const ManageBillingContent = () => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  // State for subscription collaborator count (maximum allowed)
  const [subscriptionCollaboratorCount, setSubscriptionCollaboratorCount] = useState(0);

  // State for active collaborators (currently active)
  const [activeCollaborators, setActiveCollaborators] = useState(0);

  // Initial collaborator count from subscription
  const [initialSubscriptionCollaboratorCount, setInitialSubscriptionCollaboratorCount] = useState(0);

  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  // State for success message
  const [successMessage, setSuccessMessage] = useState("");

  // State to control the visibility of the info block
  const [showInfoBlock, setShowInfoBlock] = useState(false);

  // Collaborator options for the slider
  const collaboratorOptions = [0, 1, 2, 4, 8, 12, 16, 20, 40, 60, 80];

  // State to keep track of the previous valid collaborator count
  const [previousValidCount, setPreviousValidCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch subscription details
        const subscriptionData = await stripeGetSubscriptionDetailsService();
        if (subscriptionData.subscription) {
          setSubscriptionStatus(subscriptionData.subscription.status);
          setSubscriptionCollaboratorCount(subscriptionData.subscription.collaborators || 0);
          setInitialSubscriptionCollaboratorCount(subscriptionData.subscription.collaborators || 0);
          setPreviousValidCount(subscriptionData.subscription.collaborators || 0);
        } else {
          setSubscriptionStatus(null);
          setSubscriptionCollaboratorCount(0);
          setInitialSubscriptionCollaboratorCount(0);
          setPreviousValidCount(0);
        }

        // Fetch user account details to get active collaborators
        const user = await userGetAccountService();
        if (user) {
          setActiveCollaborators(user.collaboratorCount || 0);
          console.log("Active Collaborators:", user.collaboratorCount);
        } else {
          setActiveCollaborators(0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setSubscriptionStatus(null);
        setSubscriptionCollaboratorCount(0);
        setInitialSubscriptionCollaboratorCount(0);
        setActiveCollaborators(0);
        setPreviousValidCount(0);
      }
    };

    fetchData();
  }, []);

  const basePrice = 12;
  const collaboratorPrice = 4;
  const totalPrice = basePrice + collaboratorPrice * subscriptionCollaboratorCount;

  const plan = {
    id: "subscription",
    name: "Monthly Subscription",
    basePrice,
    collaboratorPrice,
    features: [
      {
        heading: "Features",
        items: [
          "Create multiple sheets and log data from WhatsApp",
          "Manage your sheets and data on Paia Sheets",
          "Connect each sheet with various integrations",
          "Analyze your sheet data with AI",
          "Export your sheets in various formats",
        ],
      },
      {
        heading: "Collaboration",
        items: [
          "Invite collaborators to log data to your sheets via their own Paia WhatsApp chats.",
        ],
      },
    ],
  };

  const handleCheckout = async () => {
    setLoading(true);
    try {
      const sessionId = await stripeCreateCheckoutSessionService(plan.id, subscriptionCollaboratorCount);
      if (!sessionId) throw new Error("Failed to create checkout session.");

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error("Stripe Checkout Error:", error);
        alert("Error redirecting to the payment page. Please try again.");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert("There was an error processing your request. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCustomerPortal = async () => {
    setLoading(true);
    try {
      const portalUrl = await stripeCreateCustomerPortalSessionService();
      if (portalUrl) window.location.href = portalUrl;
      else alert("Unable to access the Customer Portal at this time.");
    } catch (error) {
      console.error("Error accessing Customer Portal:", error);
      alert("Error accessing the Customer Portal. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle changes in the collaborator slider
  const handleSliderChange = (e) => {
    const selectedIndex = parseInt(e.target.value, 10);
    const selectedCount = collaboratorOptions[selectedIndex];

    if (selectedCount < activeCollaborators) {
      // Prevent decreasing below active collaborators
      setShowInfoBlock(true);
      // Revert to the previous valid count after a short delay to allow the slider to visually revert
      setTimeout(() => {
        setSubscriptionCollaboratorCount(previousValidCount);
        // Reset the slider value
        e.target.value = collaboratorOptions.indexOf(previousValidCount);
        setShowInfoBlock(true);
      }, 100); // Adjust the delay as needed
    } else {
      setShowInfoBlock(false);
      setSubscriptionCollaboratorCount(selectedCount);
      setPreviousValidCount(selectedCount);
    }
  };

  const handleUpdateSubscription = async () => {
    if (subscriptionCollaboratorCount < activeCollaborators) {
      alert(
        "Please remove active collaborators before reducing your collaborator count."
      );
      return;
    }

    setLoading(true);
    try {
      await stripeUpdateSubscriptionFeatureService({
        collaboratorCount: subscriptionCollaboratorCount,
      });
      setSuccessMessage("Subscription updated successfully.");
      setInitialSubscriptionCollaboratorCount(subscriptionCollaboratorCount);
      setPreviousValidCount(subscriptionCollaboratorCount);

      // Refresh subscription details
      const subscriptionData = await stripeGetSubscriptionDetailsService();
      if (subscriptionData.subscription) {
        setSubscriptionStatus(subscriptionData.subscription.status);
        setSubscriptionCollaboratorCount(subscriptionData.subscription.collaborators || 0);
        setInitialSubscriptionCollaboratorCount(subscriptionData.subscription.collaborators || 0);
        setPreviousValidCount(subscriptionData.subscription.collaborators || 0);
      }

      // Refresh active collaborators
      const accountData = await userGetAccountService();
      if (accountData) {
        setActiveCollaborators(accountData.collaboratorCount || 0);
      } else {
        setActiveCollaborators(0);
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
      alert("There was an error updating your subscription. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const hasChanged =
    subscriptionCollaboratorCount !== initialSubscriptionCollaboratorCount;

  return (
    <div className="space-y-6">
      {/* Basic Access Section */}
      <div className="p-6 rounded-md bg-gray-100">
        <h3 className="text-xl font-semibold mb-2">Basic Access</h3>
        <p className="text-lg font-medium">Free forever</p>
        <ul className="list-disc ml-6 mt-2">
          <li>Access to Paia on WhatsApp</li>
          <li>Access to your Paia Sheets account</li>
        </ul>
      </div>

      {/* Subscription Plan Section */}
      <div className="p-6 rounded-md border border-[#3D4934] border-2">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
          {subscriptionStatus === "active" && (
            <span className="inline-block px-3 py-1 text-sm font-semibold text-green-800 bg-green-200 rounded-full">
              Active
            </span>
          )}
          {subscriptionStatus === "canceled" && (
            <span className="inline-block px-3 py-1 text-sm font-semibold text-red-800 bg-red-200 rounded-full">
              Canceled
            </span>
          )}
        </div>
        <p className="text-lg font-medium">${basePrice} / month</p>

        {/* Features List */}
        {plan.features.map((featureSet, index) => (
          <div key={index} className="mt-4">
            <h4 className="text-lg font-semibold">{featureSet.heading}</h4>
            <ul className="list-disc ml-6 mt-2">
              {featureSet.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        ))}

        {/* Collaborator Slider with Value Display */}
        <div className="mt-6">
          <label
            htmlFor="collaborator-slider"
            className="block text-sm font-medium text-gray-700 mb-4"
          >
            Number of Collaborators: {subscriptionCollaboratorCount}
          </label>
          <div className="relative">
            {/* Slider Input */}
            <input
              id="collaborator-slider"
              type="range"
              min={0}
              max={collaboratorOptions.length - 1}
              step="1"
              value={collaboratorOptions.indexOf(subscriptionCollaboratorCount)}
              onChange={handleSliderChange}
              className="w-full mt-1 accent-[#3D4934]"
            />
          </div>

          <p className="mt-2 text-sm">
            Need 80+ collaborators? Contact us at{" "}
            <a href="mailto:info@paia.chat" className="text-blue-500 underline">
              info@paia.chat
            </a>
          </p>
        </div>
      </div>

      {/* Success Message */}
      {successMessage && (
        <div
          className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-md"
          role="alert"
        >
          {successMessage}
        </div>
      )}

      {/* Info Block for Active Collaborators */}
      {showInfoBlock && (
        <div
          className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-md"
          role="alert"
        >
          You first need to remove your collaborators in order to update your
          subscription with a new amount of collaborators.
        </div>
      )}

      {/* Billing Summary Section */}
      <div className="mt-6 py-4 border-t border-gray-200">
        <h4 className="text-lg font-semibold mb-2">Billing Summary</h4>
        <div className="flex justify-between">
          <span>Base Price:</span>
          <span>${basePrice.toFixed(2)}</span>
        </div>
        <div className="flex justify-between mt-2">
          <span>Collaborators:</span>
          <span>
            {subscriptionCollaboratorCount} x ${collaboratorPrice.toFixed(2)} =
            ${(collaboratorPrice * subscriptionCollaboratorCount).toFixed(2)}
          </span>
        </div>

        {/* Prorated Charges and Credits */}
        {hasChanged && subscriptionStatus === "active" && (
          <div className="mt-4">
            <h5 className="text-md font-semibold">Prorated Adjustments:</h5>
            {subscriptionCollaboratorCount > initialSubscriptionCollaboratorCount && (
              <div className="flex justify-between">
                <span>Additional Collaborators:</span>
                <span>
                  +$
                  {(
                    collaboratorPrice *
                    (subscriptionCollaboratorCount - initialSubscriptionCollaboratorCount)
                  ).toFixed(2)}
                </span>
              </div>
            )}
            {subscriptionCollaboratorCount < initialSubscriptionCollaboratorCount && (
              <div className="flex justify-between">
                <span>Removed Collaborators:</span>
                <span>
                  -$
                  {(
                    collaboratorPrice *
                    (initialSubscriptionCollaboratorCount - subscriptionCollaboratorCount)
                  ).toFixed(2)}
                </span>
              </div>
            )}
          </div>
        )}

        <div className="flex justify-between mt-2 border-t border-gray-200 pt-2">
          <span className="font-semibold">Total:</span>
          <span className="font-semibold">
            ${totalPrice.toFixed(2)} / month
          </span>
        </div>
      </div>

      {/* Conditional Rendering for Action Buttons */}
      {subscriptionStatus === "active" && hasChanged ? (
        <button
          onClick={handleUpdateSubscription}
          disabled={loading}
          className={`mt-6 w-full p-3 bg-[#3D4934] text-white rounded-md ${
            loading ? "cursor-not-allowed" : "hover:bg-[#2C3927]"
          }`}
        >
          {loading ? "Updating..." : "Update Subscription"}
        </button>
      ) : !subscriptionStatus ? (
        <button
          onClick={handleCheckout}
          disabled={loading}
          className={`mt-6 w-full p-3 bg-[#3D4934] text-white rounded-md ${
            loading ? "cursor-not-allowed" : "hover:bg-[#2C3927]"
          }`}
        >
          {loading ? "Processing..." : "Subscribe"}
        </button>
      ) : (
        <button
          onClick={handleCustomerPortal}
          disabled={loading}
          className={`mt-4 w-full p-3 ${
            loading ? "cursor-not-allowed" : "hover:bg-gray-50"
          }`}
        >
          {loading ? "Loading..." : "Manage Subscription"}
        </button>
      )}
    </div>
  );
};

export default ManageBillingContent;
